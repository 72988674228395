import { useMutation, useQueryClient } from "react-query";

import { User } from "../models";
import { updateUser } from "../services/users";
import { userQueryKey } from "./useUserQuery";

export function useUpdateUserMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ user, updates }: { user: User; updates: Partial<User> }) => {
      return updateUser(user, updates);
    },
    {
      onMutate: ({ user, updates }) => {
        const optimisticUser = { ...user, ...updates };
        queryClient.setQueryData(userQueryKey(user.id), optimisticUser);
      },
      onSettled: (data, error, { user }) => {
        queryClient.invalidateQueries(userQueryKey(user.id));
      },
    }
  );
}
