import {
  useCallback,
  useMemo,
  useState,
  CSSProperties,
  TouchEvent,
  TouchEventHandler,
} from "react";

export interface UseOnSwipeOptions {
  swipeThreshold?: number;
  onSwipeLeft?: () => void;
  onSwipeRight?: () => void;
}

export function useSwipeable({
  swipeThreshold = 100,
  onSwipeLeft,
  onSwipeRight,
}: UseOnSwipeOptions) {
  const [isDragging, setIsDragging] = useState(false);
  const [shouldFlingRight, setShouldFlingRight] = useState(false);
  const [shouldFlingLeft, setShouldFlingLeft] = useState(false);
  const [x1, setX1] = useState(0);
  const [x2, setX2] = useState(0);
  const [y1, setY1] = useState(0);
  const [y2, setY2] = useState(0);
  const dX = x2 - x1;
  const dY = y2 - y1;

  const onTouchStart: TouchEventHandler = useCallback((event) => {
    if (isMultiTouch(event)) {
      return;
    }
    event.stopPropagation();
    const [x, y] = getTouchCoordinates(event);
    setX1(x);
    setX2(x);
    setY1(y);
    setY2(y);
  }, []);

  const onTouchMove: TouchEventHandler = useCallback(
    (event) => {
      if (isMultiTouch(event)) {
        return;
      }
      event.stopPropagation();
      const [x, y] = getTouchCoordinates(event);
      setX2(x);
      setY2(y);
      if (!isDragging && Math.abs(dX) > Math.abs(dY)) {
        setX1(x);
        setIsDragging(true);
      }
    },
    [dX, dY, isDragging]
  );

  const onTouchEnd: TouchEventHandler = useCallback(
    (event) => {
      if (isMultiTouch(event)) {
        return;
      }
      event.stopPropagation();
      if (dX > swipeThreshold && onSwipeRight) {
        setShouldFlingRight(true);
      } else if (dX < -swipeThreshold && onSwipeLeft) {
        setShouldFlingLeft(true);
      } else {
        setIsDragging(false);
      }
    },
    [dX, swipeThreshold, onSwipeLeft, onSwipeRight]
  );

  const onTransitionEnd = useCallback(() => {
    if (shouldFlingLeft) {
      onSwipeLeft?.();
    } else if (shouldFlingRight) {
      onSwipeRight?.();
    }
    setIsDragging(false);
    setShouldFlingLeft(false);
    setShouldFlingRight(false);
  }, [shouldFlingLeft, shouldFlingRight, onSwipeLeft, onSwipeRight]);

  const style = useMemo<CSSProperties | undefined>(() => {
    let result: CSSProperties = {};
    let transformDistance;
    if (!isDragging) {
      transformDistance = "0px";
    } else if (shouldFlingLeft) {
      transformDistance = "-100vw";
    } else if (shouldFlingRight) {
      transformDistance = "100vw";
    } else if ((dX < 0 && onSwipeLeft) || (dX > 0 && onSwipeRight)) {
      transformDistance = `${dX}px`;
    }
    if (transformDistance) {
      result.transform = `translateX(${transformDistance})`;
    }
    if (!isDragging || shouldFlingLeft || shouldFlingRight) {
      result.transition = "transform .2s ease-out";
    }
    return result;
  }, [
    dX,
    isDragging,
    shouldFlingLeft,
    shouldFlingRight,
    onSwipeLeft,
    onSwipeRight,
  ]);

  return { onTouchStart, onTouchMove, onTouchEnd, onTransitionEnd, style };
}

function getTouchCoordinates(event: TouchEvent) {
  if (event.touches.length === 0) {
    return [0, 0];
  }
  const { clientX, clientY } = event.touches[0];
  return [clientX, clientY];
}

function isMultiTouch(event: TouchEvent) {
  return event.touches.length > 1;
}
