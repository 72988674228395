import { useVisibilityTracker } from "../hooks/useVisibilityTracker";

export interface VisibilityElementProps {
  onVisible: () => void;
}

export function VisibilityElement({ onVisible }: VisibilityElementProps) {
  const { ref: visibilityRef } = useVisibilityTracker({ onVisible });
  return <div ref={visibilityRef} />;
}
