import { useQuery } from "react-query";
import { getUserById } from "../services/users";

export function userQueryKey(userId: string) {
  return ["users", userId];
}

export function useUserQuery(userId: string) {
  return useQuery(userQueryKey(userId), () => getUserById(userId));
}
