import { useQuery } from "react-query";

import { getUnreadComments } from "../services/comments";

export function unreadCommentsQueryKey() {
  return ["unreadComments"];
}

export function useUnreadCommentsQuery(userId: string) {
  return useQuery(unreadCommentsQueryKey(), () => {
    return getUnreadComments(userId);
  });
}
