import { useQuery } from "react-query";
import { getPost } from "../services/posts";

export function postQueryKey(postId: string) {
  return ["posts", postId];
}

export function usePostQuery(postId: string) {
  return useQuery(postQueryKey(postId), () => getPost(postId), {
    enabled: !!postId,
  });
}
