import classNames from "classnames";

import styles from "./SortAndFilterControls.module.css";
import { TopBar } from "./TopBar";
import { SortBy, FilterBy } from "../services/posts";

export interface SortAndFilterControlsProps {
  filterBy: FilterBy;
  sortBy: SortBy;
  onChangeFilterBy: (filterBy: FilterBy) => void;
  onChangeSortBy: (sortBy: SortBy) => void;
}

export function SortAndFilterControls({
  filterBy,
  sortBy,
  onChangeFilterBy,
  onChangeSortBy,
}: SortAndFilterControlsProps) {
  const filterButtons = [
    {
      label: "All",
      value: FilterBy.All,
    },
    {
      label: "Her likes",
      value: FilterBy.HerLikes,
    },
    {
      label: "His likes",
      value: FilterBy.HisLikes,
    },
    {
      label: "Liked by both",
      value: FilterBy.LikedByBoth,
    },
    {
      label: "Has comments",
      value: FilterBy.HasComments,
    },
    {
      label: "No tags",
      value: FilterBy.NoTags,
    },
  ];

  const sortOptions = [
    {
      label: "Newest",
      value: SortBy.Newest,
    },
    {
      label: "Oldest",
      value: SortBy.Oldest,
    },
    {
      label: "Random",
      value: SortBy.Random,
    },
  ];

  if (filterBy === FilterBy.HerLikes || filterBy === FilterBy.HisLikes) {
    sortOptions.push({
      label: "Liked recently",
      value: SortBy.LikedRecently,
    });
  }

  if (filterBy === FilterBy.HasComments) {
    sortOptions.push({
      label: "Commented recently",
      value: SortBy.CommentedRecently,
    });
  }

  return (
    <div className={styles.controlsContainer}>
      <TopBar className={styles.filterBar}>
        {filterButtons.map(({ label, value }) => {
          return (
            <button
              key={value}
              className={classNames(
                styles.pillButton,
                filterBy === value && styles.active
              )}
              onClick={() => {
                onChangeFilterBy(value);
                onChangeSortBy(SortBy.Newest);
              }}
            >
              {label}
            </button>
          );
        })}
      </TopBar>
      <div className={styles.selectorContainer}>
        <select
          className={styles.selector}
          onChange={(event) => {
            const sortBy = parseInt(event.target.value) as SortBy;
            onChangeSortBy(sortBy);
          }}
          value={sortBy}
        >
          {sortOptions.map(({ label, value }) => {
            return (
              <option key={value} value={value} label={`Sort by: ${label} ▼`}>
                {label}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}
