import { useMutation, useQueryClient } from "react-query";

import { Tag } from "../models";
import { updateTag } from "../services/tags";
import { tagsQueryKey } from "./useTagsQuery";

export function useUpdateTagMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    (updates: Tag) => {
      return updateTag(updates);
    },
    {
      onMutate: (updates) => {
        queryClient.setQueryData<Tag[] | undefined>(tagsQueryKey, (oldTags) => {
          return (oldTags || []).map((tag) => {
            if (tag.id === updates.id) {
              return updates;
            }
            return tag;
          });
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(tagsQueryKey);
      },
    }
  );
}
