import { useMutation, useQueryClient } from "react-query";
import { createTag } from "../services/tags";
import { tagsQueryKey } from "./useTagsQuery";

export function useCreateTagMutation() {
  const queryClient = useQueryClient();

  return useMutation((name: string) => createTag(name), {
    onSettled: () => {
      queryClient.invalidateQueries(tagsQueryKey);
    },
  });
}
