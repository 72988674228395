import {
  QueryConstraint,
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
  startAfter,
  CollectionReference,
  serverTimestamp,
  setDoc,
  UpdateData,
} from "firebase/firestore";
import { uploadBytes, ref } from "firebase/storage";

import { Post, PostImage, Tag, User } from "../models";
import { firestore, getDownloadUrl, storage } from "./firebase";

export const postsCollection = collection(
  firestore,
  "posts"
) as CollectionReference<Post>;

export enum FilterBy {
  All,
  HisLikes,
  HerLikes,
  LikedByBoth,
  HasComments,
  NoTags,
}

export enum SortBy {
  Newest,
  Oldest,
  Random,
  LikedRecently,
  CommentedRecently,
}

export interface GetPostsOptions {
  limit: number;
  sortBy?: SortBy;
  filterBy?: FilterBy;
  startAfter?: Post;
  tags?: Tag[];
}

export async function getPost(postId: string): Promise<Post | undefined> {
  const snapshot = await getDoc(doc(postsCollection, postId));
  return snapshot.data();
}

export async function updatePost(
  postId: string,
  updates: UpdateData<Post>
): Promise<void> {
  await updateDoc(doc(postsCollection, postId), updates);
}

export async function deletePost(post: Post): Promise<void> {
  await updatePost(post.id, { isDeleted: true });
}

export async function getPosts(options: GetPostsOptions): Promise<Post[]> {
  if (options.sortBy === SortBy.Random) {
    return getRandomPosts(options);
  }
  const sortAndFilterConstraints = createSortAndFilterQueryConstraints(
    options.filterBy,
    options.sortBy
  );
  const constraints = [...sortAndFilterConstraints];
  if (options.limit) {
    constraints.push(limit(options.limit));
  }
  if (options.startAfter) {
    const documentReference = doc(postsCollection, options.startAfter.id);
    const documentSnapshot = await getDoc(documentReference);
    if (documentSnapshot.exists()) {
      constraints.push(startAfter(documentSnapshot));
    }
  }
  if (options.tags) {
    for (const tag of options.tags) {
      constraints.push(where(`tags.${tag.id}`, "==", true));
    }
  }
  return internalGetPosts(constraints);
}

export async function ratePost(
  user: User,
  post: Post,
  isLiked: boolean
): Promise<void> {
  await updatePost(
    post.id,
    user.isHim
      ? {
          heLiked: isLiked,
          heViewed: true,
          heLikedAt: isLiked ? serverTimestamp() : null,
        }
      : {
          sheLiked: isLiked,
          sheViewed: true,
          sheLikedAt: isLiked ? serverTimestamp() : null,
        }
  );
}

export function doesUserLikePost(user: User, post: Post) {
  return user.isHim ? post.heLiked : post.sheLiked;
}

export async function getRandomUnratedPost(user: User): Promise<Post | null> {
  return getRandomPost([
    where(user.isHim ? "heViewed" : "sheViewed", "==", false),
  ]);
}

export async function createPost(files: File[]) {
  const docRef = doc(postsCollection);
  const images = await Promise.all(files.map(createPostImage));
  return setDoc(docRef, {
    images,
    id: docRef.id,
    heViewed: false,
    heLiked: false,
    heLikedAt: null,
    sheViewed: false,
    sheLiked: false,
    sheLikedAt: null,
    hasComments: false,
    lastCommentAt: null,
    createdAt: serverTimestamp(),
    tags: {},
    hasTags: false,
    isDeleted: false,
  });
}

async function createPostImage(file: File): Promise<PostImage> {
  const extension = file.name.split(".").pop();
  const storagePath = `images/${generateId()}.${extension}`;
  const storageRef = ref(storage, storagePath);
  await uploadBytes(storageRef, file);
  const url = await getDownloadUrl(storagePath);
  const { width, height } = await getImageFileDimensions(file);
  return { width, height, storagePath, url };
}

function getImageFileDimensions(
  file: File
): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    const url = URL.createObjectURL(file);
    image.onload = () => {
      URL.revokeObjectURL(url);
      resolve({ height: image.height, width: image.width });
    };
    image.onerror = reject;
    image.src = url;
  });
}

async function getRandomPost(
  constraints: QueryConstraint[]
): Promise<Post | null> {
  // https://stackoverflow.com/questions/46798981/firestore-how-to-get-random-documents-in-a-collection
  const randomId = doc(postsCollection).id;
  const firstAttempt = await internalGetPosts([
    ...constraints,
    where(documentId(), ">=", randomId),
    limit(1),
  ]);
  if (firstAttempt.length > 0) {
    return firstAttempt[0];
  }
  const secondAttempt = await internalGetPosts([
    ...constraints,
    where(documentId(), "<", randomId),
    limit(1),
  ]);
  return secondAttempt.length > 0 ? secondAttempt[0] : null;
}

function getRandomPosts(options: GetPostsOptions): Promise<Post[]> {
  const constraints = createSortAndFilterQueryConstraints(
    options.filterBy,
    options.sortBy
  );
  const promises = [];
  for (let i = 0; i < options.limit; i++) {
    promises.push(getRandomPost(constraints));
  }
  return Promise.all(promises).then((posts) => {
    return posts.filter((post): post is Post => post !== null);
  });
}

function internalGetPosts(constraints: QueryConstraint[]): Promise<Post[]> {
  return getDocs(
    query(postsCollection, where("isDeleted", "==", false), ...constraints)
  ).then((snapshot) => snapshot.docs.map((doc) => doc.data()));
}

function createSortAndFilterQueryConstraints(
  filterBy?: FilterBy,
  sortBy?: SortBy
) {
  const constraints: QueryConstraint[] = [];
  const herLikesConstraint = where("sheLiked", "==", true);
  const hisLikesConstraint = where("heLiked", "==", true);

  switch (filterBy) {
    case FilterBy.HerLikes:
      constraints.push(herLikesConstraint);
      break;
    case FilterBy.HisLikes:
      constraints.push(hisLikesConstraint);
      break;
    case FilterBy.LikedByBoth:
      constraints.push(herLikesConstraint, hisLikesConstraint);
      break;
    case FilterBy.HasComments:
      constraints.push(where("hasComments", "==", true));
      break;
    case FilterBy.NoTags:
      constraints.push(where("hasTags", "==", false));
      break;
    default:
  }

  switch (sortBy) {
    case SortBy.Newest:
      constraints.push(orderBy("createdAt", "desc"));
      break;
    case SortBy.Oldest:
      constraints.push(orderBy("createdAt", "asc"));
      break;
    case SortBy.LikedRecently:
      if (filterBy === FilterBy.HerLikes) {
        constraints.push(orderBy("sheLikedAt", "desc"));
      }
      if (filterBy === FilterBy.HisLikes) {
        constraints.push(orderBy("heLikedAt", "desc"));
      }
      break;
    case SortBy.CommentedRecently:
      if (filterBy === FilterBy.HasComments) {
        constraints.push(orderBy("lastCommentAt", "desc"));
      }
      break;
    default:
  }

  return constraints;
}

function generateId() {
  return doc(postsCollection).id;
}
