import classNames from "classnames";
import { ReactNode, useState } from "react";

import styles from "./SwipeablePanel.module.css";
import { useSwipeable } from "../hooks/useSwipeable";
import { SafeAreaFullHeightDiv } from "./SafeAreaFullHeightDiv";
import { TopBar } from "./TopBar";

export interface SwipeablePanelProps {
  children: ReactNode;
  isVisible: boolean;
  className?: string;
  topLeft?: ReactNode;
  topCenter?: ReactNode;
  topRight?: ReactNode;
  onHide: () => void;
}

export function SwipeablePanel(props: SwipeablePanelProps) {
  const {
    children,
    isVisible,
    className,
    topLeft,
    topCenter,
    topRight,
    onHide,
  } = props;
  const [skipNextAnimation, setSkipNextAnimation] = useState(false);
  const [animationClassName, setAnimationClassName] = useState<
    string | undefined
  >(undefined);

  const swipeProps = useSwipeable({
    onSwipeRight: () => {
      setSkipNextAnimation(true);
      onHide();
    },
  });

  const [prevIsVisible, setPrevIsVisible] = useState(isVisible);
  if (prevIsVisible !== isVisible) {
    setPrevIsVisible(isVisible);
    if (skipNextAnimation) {
      setSkipNextAnimation(false);
      setAnimationClassName(undefined);
    } else {
      setAnimationClassName(isVisible ? styles.show : styles.hide);
    }
  }

  return (
    <SafeAreaFullHeightDiv
      {...swipeProps}
      className={classNames(
        !isVisible && styles.hidden,
        animationClassName,
        className
      )}
    >
      <TopBar>
        <div className={styles.topLeft}>{topLeft}</div>
        <div>{topCenter}</div>
        <div className={styles.topRight}>{topRight}</div>
      </TopBar>
      {children}
    </SafeAreaFullHeightDiv>
  );
}
