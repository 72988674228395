import { ComponentPropsWithoutRef } from "react";
import classNames from "classnames";

import styles from "./SafeAreaFullHeightDiv.module.css";

export function SafeAreaFullHeightDiv(props: ComponentPropsWithoutRef<"div">) {
  return (
    <div
      {...props}
      className={classNames(styles.safeAreaFullHeightDiv, props.className)}
    />
  );
}
