import {
  ChangeEventHandler,
  ClipboardEventHandler,
  useMemo,
  useRef,
  useState,
} from "react";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./NewPostPanel.module.css";
import { Button } from "./Button";
import { useCreatePostMutation } from "../hooks/useCreatePostMutation";
import { SwipeablePanel } from "./SwipeablePanel";

export interface NewPostPanelProps {
  isVisible: boolean;
  onHide: () => void;
}

export function NewPostPanel(props: NewPostPanelProps) {
  const { isVisible, onHide } = props;
  const [files, setFiles] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const pasteAreaRef = useRef<HTMLDivElement>(null);
  const { mutateAsync: createPost, isLoading: isLoadingCreatePost } =
    useCreatePostMutation();

  const [prevIsVisible, setPrevIsVisible] = useState(isVisible);
  if (prevIsVisible !== isVisible) {
    setPrevIsVisible(isVisible);
    setFiles([]);
  }

  const imageUrls = useMemo(() => {
    return files.map((file) => URL.createObjectURL(file));
  }, [files]);

  const handlePaste: ClipboardEventHandler = (event) => {
    event.preventDefault();
    const images = Array.from(event.clipboardData.items).filter((item) => {
      return /image/.test(item.type);
    });
    if (images.length === 0) {
      return;
    }
    const files = images
      .map((image) => image.getAsFile())
      .filter((file): file is File => !!file);
    setFiles((oldFiles) => [...oldFiles, ...files]);
    pasteAreaRef.current?.blur();
  };

  const handleSelectFile: ChangeEventHandler<HTMLInputElement> = (event) => {
    const inputFiles = event.target.files;
    if (!inputFiles) {
      return;
    }
    setFiles((oldFiles) => [...oldFiles, ...Array.from(inputFiles)]);
  };

  async function handleCreatePost() {
    await createPost(files);
    onHide();
  }

  return (
    <SwipeablePanel
      isVisible={isVisible}
      onHide={onHide}
      topLeft={<Button onClick={onHide}>Cancel</Button>}
      topCenter="New post"
      topRight={
        <Button
          onClick={handleCreatePost}
          disabled={isLoadingCreatePost || files.length === 0}
        >
          {isLoadingCreatePost ? "Saving..." : "Save"}
        </Button>
      }
    >
      <div className={styles.uploadControls}>
        <Button onClick={() => fileInputRef.current?.click()}>
          Choose file &nbsp;
          <FontAwesomeIcon icon={faFileArrowUp} />
        </Button>
        or
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*,.webp"
          hidden
          onChange={handleSelectFile}
        />
        <div
          onPaste={handlePaste}
          contentEditable="true"
          className={styles.pasteArea}
          ref={pasteAreaRef}
        />
      </div>
      <div className={styles.imagePreviews}>
        {imageUrls.map((url) => {
          return <img key={url} src={url} alt="pasted content" />;
        })}
      </div>
    </SwipeablePanel>
  );
}
