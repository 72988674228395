import { RefObject, useState } from "react";
import { useOutletContext } from "react-router-dom";

import styles from "./SettingsTab.module.css";
import { useUpdateUserMutation } from "../hooks/useUpdateUserMutation";
import { useCurrentUser } from "../hooks/useCurrentUser";
import { useTagsQuery } from "../hooks/useTagsQuery";
import { Switch } from "./Switch";
import { TopBar } from "./TopBar";
import { Input } from "./Input";
import { EditTagControls } from "./EditTagControls";

export function SettingsTab() {
  const [searchValue, setSearchValue] = useState("");
  const user = useCurrentUser();
  const { data: tags } = useTagsQuery();
  const { mutate: updateUser } = useUpdateUserMutation();
  const { scrollToTopRef } =
    useOutletContext<{ scrollToTopRef: RefObject<HTMLDivElement> }>();

  const sortedAndFilteredTags = tags
    ?.filter((tag) => {
      const searchValueLower = searchValue.trim().toLocaleLowerCase();
      return (
        tag.name.toLocaleLowerCase().includes(searchValueLower) ||
        tag.alternateName?.toLocaleLowerCase().includes(searchValueLower)
      );
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className={styles.container} ref={scrollToTopRef}>
      <TopBar className={styles.title}>User preferences</TopBar>
      <div className={styles.section}>
        <div className={styles.preference}>
          <span className={styles.description}>Use alternate tag names</span>
          <Switch
            checked={user.useAlternateTagNames}
            onChange={(event) =>
              updateUser({
                user,
                updates: { useAlternateTagNames: event.target.checked },
              })
            }
          />
        </div>
      </div>
      <TopBar className={styles.title}>Manage tags</TopBar>
      <div className={styles.section}>
        <Input
          className={styles.searchInput}
          placeholder="Search tags"
          value={searchValue}
          onChange={(event) => setSearchValue(event.target.value)}
        />
        {sortedAndFilteredTags?.map((tag) => {
          return <EditTagControls key={tag.id} tag={tag} />;
        })}
      </div>
    </div>
  );
}
