import { useMutation, useQueryClient } from "react-query";

import { Comment } from "../models";
import { updateComment } from "../services/comments";
import { commentsQueryKey } from "./useCommentsQuery";
import { unreadCommentsQueryKey } from "./useUnreadCommentsQuery";

export function useUpdateCommentMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      postId,
      commentId,
      updates,
    }: {
      postId: string;
      commentId: string;
      updates: Partial<Comment>;
    }) => {
      return updateComment(postId, commentId, updates);
    },
    {
      onMutate: ({ postId, commentId, updates }) => {
        const queryKey = commentsQueryKey(postId);
        const comments = queryClient.getQueryData<Comment[]>(queryKey);
        const optimisticComments = comments?.map((comment) => {
          if (comment.id === commentId) {
            return { ...comment, ...updates };
          } else {
            return comment;
          }
        });
        queryClient.setQueryData(queryKey, optimisticComments);
      },
      onSettled: (data, error, { postId }) => {
        queryClient.invalidateQueries(unreadCommentsQueryKey());
        queryClient.invalidateQueries(commentsQueryKey(postId));
      },
    }
  );
}
