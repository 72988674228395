import { RefObject, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImages } from "@fortawesome/free-solid-svg-icons";
import { useOutletContext } from "react-router-dom";

import styles from "./SearchTab.module.css";
import { Post, Tag } from "../models";
import { TagPicker } from "./TagPicker";
import { useInfinitePostsQuery } from "../hooks/useInfinitePostsQuery";
import { PostImageLoader } from "./PostImageLoader";
import { Masonry } from "./Masonry";
import { FeedFooter } from "./FeedFooter";
import { VisibilityElement } from "./VisibilityElement";
import { TagFeedPanel } from "./TagFeedPanel";
import { GetPostsOptions } from "../services/posts";
import { usePanelNavigation } from "../hooks/usePanelNavigation";

export function SearchTab() {
  const [selectedPost, setSelectedPost] = useState<Post | null>(null);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const panelNavigation = usePanelNavigation();

  const getPostsOptions: GetPostsOptions = { limit: 10, tags: selectedTags };

  const { data, isLoading, hasNextPage, fetchNextPage } =
    useInfinitePostsQuery(getPostsOptions);
  const { scrollToTopRef } =
    useOutletContext<{ scrollToTopRef: RefObject<HTMLDivElement> }>();

  function handleClickPost(post: Post) {
    setSelectedPost(post);
    panelNavigation.showTagFeedPanel();
  }

  function handleClickBackFromTagFeed() {
    panelNavigation.hideTagFeedPanel();
    setSelectedPost(null);
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <TagPicker onChange={setSelectedTags} value={selectedTags} />
        </div>
        <div className={styles.content} ref={scrollToTopRef}>
          <Masonry columns={2}>
            {data?.pages.flat().map((post) => {
              const isAlbum = post.images.length > 1;
              return (
                <div
                  key={post.id}
                  className={styles.item}
                  onClick={() => handleClickPost(post)}
                >
                  <PostImageLoader postImage={post.images[0]} />
                  {isAlbum && (
                    <FontAwesomeIcon
                      icon={faImages}
                      className={styles.albumIcon}
                    />
                  )}
                </div>
              );
            })}
            {/* Two visibility elements so each column has one */}
            {hasNextPage && <VisibilityElement onVisible={fetchNextPage} />}
            {hasNextPage && <VisibilityElement onVisible={fetchNextPage} />}
          </Masonry>
          <FeedFooter showSpinner={isLoading || !!hasNextPage} />
        </div>
      </div>
      <TagFeedPanel
        isVisible={panelNavigation.isTagFeedVisible}
        getPostsOptions={getPostsOptions}
        scrollToPostId={selectedPost?.id}
        onHide={handleClickBackFromTagFeed}
      />
    </>
  );
}
