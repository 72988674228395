import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./NotificationsButton.module.css";
import { Button } from "./Button";
import { useUnreadCommentsQuery } from "../hooks/useUnreadCommentsQuery";
import { useCurrentUser } from "../hooks/useCurrentUser";

export interface NotificationsButtonProps {
  onClick: () => void;
}

export function NotificationsButton(props: NotificationsButtonProps) {
  const { onClick } = props;
  const user = useCurrentUser();
  const { data: unreadComments } = useUnreadCommentsQuery(user.id);

  return (
    <div className={styles.container}>
      {!!unreadComments && unreadComments.length > 0 && (
        <div className={styles.count}>{unreadComments.length}</div>
      )}
      <Button onClick={onClick}>
        <FontAwesomeIcon icon={faBell} />
      </Button>
    </div>
  );
}
