import { signInWithEmailAndPassword } from "firebase/auth";
import { useEffect, createContext, ReactNode, useState } from "react";

import { auth } from "../services/firebase";

interface AuthContext {
  isInitialized: boolean;
  userId: string | null;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
}

const defaultAuthContext: AuthContext = {
  isInitialized: false,
  userId: null,
  signIn,
  signOut,
};

async function signIn(email: string, password: string): Promise<void> {
  await signInWithEmailAndPassword(auth, email, password);
}

async function signOut(): Promise<void> {
  await auth.signOut();
}

export const authContext = createContext<AuthContext>(defaultAuthContext);

export interface AuthProviderProps {
  children?: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [userId, setUserId] = useState<string | null>(null);

  useEffect(() => {
    return auth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        setUserId(firebaseUser.uid);
      } else {
        setUserId(null);
      }
      setIsInitialized(true);
    });
  }, []);

  return (
    <authContext.Provider value={{ isInitialized, userId, signIn, signOut }}>
      {children}
    </authContext.Provider>
  );
}
