import { ComponentPropsWithoutRef } from "react";
import { useId } from "../hooks/useId";

import styles from "./Switch.module.css";

type OmittedInputProps = "className" | "id" | "type";

export type SwitchProps = Omit<
  ComponentPropsWithoutRef<"input">,
  OmittedInputProps
>;

export function Switch(props: SwitchProps) {
  const id = useId();

  return (
    <label htmlFor={id} className={styles.toggle}>
      <input id={id} type="checkbox" className={styles.input} {...props} />
      <div className={styles.fill} />
    </label>
  );
}
