import { useMutation, useQueryClient } from "react-query";

import { Comment } from "../models";
import { deleteComment } from "../services/comments";
import { commentsQueryKey } from "./useCommentsQuery";

export function useDeleteCommentMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ postId, commentId }: { postId: string; commentId: string }) => {
      return deleteComment(postId, commentId);
    },
    {
      onMutate: ({ postId, commentId }) => {
        queryClient.setQueryData<Comment[] | undefined>(
          commentsQueryKey(postId),
          (oldComments) => {
            return oldComments?.filter(
              (oldComment) => oldComment.id !== commentId
            );
          }
        );
      },
      onSettled: (data, error, { postId }) => {
        queryClient.invalidateQueries(commentsQueryKey(postId));
      },
    }
  );
}
