import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

import styles from "./PostWithFeedControls.module.css";
import { Post } from "../models";
import { useCurrentUser } from "../hooks/useCurrentUser";
import { doesUserLikePost } from "../services/posts";
import { HeartAnimation } from "./HeartAnimation";
import { FeedControls } from "./FeedControls";
import { PostImages } from "./PostImages";
import { AbbreviatedComment } from "./AbbreviatedComment";
import { useCommentsQuery } from "../hooks/useCommentsQuery";
import { DateLabel } from "./DateLabel";
import { useDeletePostMutation } from "../hooks/useDeletePostMutation";
import { useRatePostMutation } from "../hooks/useRatePostMutation";
import { usePanelNavigation } from "../hooks/usePanelNavigation";
import { useOtherUser } from "../hooks/useOtherUser";

export interface PostWithFeedControlsProps {
  post: Post;
  showSeparator?: boolean;
}

export function PostWithFeedControls(props: PostWithFeedControlsProps) {
  const { post, showSeparator = false } = props;
  const [isLiked, setIsLiked] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isHeartAnimationVisible, setIsHeartAnimationVisible] = useState(false);
  const currentUser = useCurrentUser();
  const otherUser = useOtherUser(currentUser);
  const panelNavigation = usePanelNavigation();
  const { mutate: deletePost } = useDeletePostMutation();
  const { mutate: ratePost } = useRatePostMutation();
  const { data: comments, isLoading: isLoadingComments } = useCommentsQuery(
    post.id
  );
  const doesPartnerLikePost = currentUser.isHim ? post.sheLiked : post.heLiked;

  useEffect(() => {
    setIsLiked(doesUserLikePost(currentUser, post));
  }, [currentUser, post]);

  function handleClickLikeToggle() {
    setIsHeartAnimationVisible(true);
    setIsLiked(!isLiked);
    ratePost({ user: currentUser, post, isLiked: !isLiked });
  }

  function handleClickDelete() {
    if (window.confirm("Are you sure you want to delete this post?")) {
      deletePost(post);
      setIsDeleted(true);
    }
  }

  function navigateToComments() {
    panelNavigation.showCommentsPanel(post.id);
  }

  return isDeleted || isLoadingComments ? null : (
    <div className={showSeparator ? styles.separator : undefined}>
      <div className={styles.relative}>
        <div onDoubleClick={handleClickLikeToggle}>
          <PostImages post={post} />
        </div>
        {isHeartAnimationVisible && (
          <HeartAnimation
            isLiked={isLiked}
            onAnimationEnd={() => setIsHeartAnimationVisible(false)}
          />
        )}
      </div>
      <FeedControls
        isLiked={isLiked}
        onClickDelete={handleClickDelete}
        onClickTags={() => panelNavigation.showPostTagsPanel(post.id)}
        onClickComments={navigateToComments}
        onClickLikeToggle={handleClickLikeToggle}
      />
      <div className={styles.footer}>
        {otherUser && doesPartnerLikePost && (
          <div className={styles.partnerLikeContainer}>
            <FontAwesomeIcon icon={faHeart} />
            <span>
              Liked by <b>{otherUser.displayName}</b>
            </span>
          </div>
        )}
        {comments && comments.length > 0 && (
          <AbbreviatedComment
            comment={comments[0]}
            onClick={navigateToComments}
          />
        )}
        {comments && comments.length > 1 && (
          <button
            className={styles.viewAllCommentsButton}
            onClick={navigateToComments}
          >
            View all {comments.length} comments
          </button>
        )}
        <DateLabel date={post.createdAt.toDate()} />
      </div>
    </div>
  );
}
