import { useContext } from "react";

import { currentUserContext } from "../components/CurrentUserProvider";

export function useCurrentUser() {
  const user = useContext(currentUserContext);

  if (!user) {
    throw new Error("useCurrentUser must be used within a CurrentUserProvider");
  }

  return user;
}
