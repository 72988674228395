import { useQuery } from "react-query";

import { User } from "../models";
import { getRandomUnratedPost } from "../services/posts";

export const randomUnratedPostQueryKey = "randomUnratedPost";

export function useRandomUnratedPostQuery(user: User) {
  return useQuery(randomUnratedPostQueryKey, () => {
    return getRandomUnratedPost(user);
  });
}
