import {
  ComponentPropsWithoutRef,
  ReactElement,
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import styles from "./Menu.module.css";
import { Button } from "./Button";

export interface MenuProps {
  position?: MenuPosition;
  children: [ReactElement<MenuButtonProps>, ReactElement<MenuDropdownProps>];
}

export interface MenuDropdownProps {
  children?: ReactElement<MenuItemProps> | ReactElement<MenuItemProps>[];
}

export type MenuItemProps = ComponentPropsWithoutRef<"button">;
export type MenuButtonProps = ComponentPropsWithoutRef<"button">;
export type MenuPosition = "left" | "right";

export type MenuContextValues = {
  position: MenuPosition;
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
};

const menuContext = createContext<MenuContextValues | undefined>(undefined);

export function Menu(props: MenuProps) {
  const { children, position = "left" } = props;
  const [isVisible, setIsVisible] = useState(false);

  return (
    <menuContext.Provider
      value={{
        position,
        isVisible,
        setIsVisible,
      }}
    >
      <div className={styles.container}>{children}</div>
    </menuContext.Provider>
  );
}

function MenuButton(props: MenuButtonProps) {
  const { setIsVisible } = useMenu();
  return <Button {...props} onClick={() => setIsVisible((prev) => !prev)} />;
}

function MenuDropdown(props: MenuDropdownProps) {
  const { children } = props;
  const { isVisible, position, setIsVisible } = useMenu();
  const [element, setElement] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    function closeOnOutsideClick(event: MouseEvent) {
      if (isVisible && element && !element.contains(event.target as Node)) {
        setIsVisible(false);
      }
    }

    if (isVisible) {
      document.addEventListener("mousedown", closeOnOutsideClick);
    }
    return () => document.removeEventListener("mousedown", closeOnOutsideClick);
  }, [element, isVisible, setIsVisible]);

  return isVisible ? (
    <div ref={setElement} className={styles.dropdown} style={{ [position]: 0 }}>
      {children}
    </div>
  ) : null;
}

function MenuItem(props: MenuItemProps) {
  const { onClick } = props;
  const { setIsVisible } = useMenu();

  return (
    <Button
      {...props}
      className={styles.menuItem}
      onClick={(event) => {
        onClick?.(event);
        setIsVisible(false);
      }}
    />
  );
}

function useMenu() {
  const context = useContext(menuContext);
  if (context === undefined) {
    throw new Error("useMenu must be used in a MenuProvider");
  }
  return context;
}

Menu.Button = MenuButton;
Menu.Dropdown = MenuDropdown;
Menu.Item = MenuItem;
