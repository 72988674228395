import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

import { AuthenticatedContent } from "./AuthenticatedContent";
import { HomeTab } from "./HomeTab";
import { LoginPage } from "./LoginPage";
import { RateTab } from "./RateTab";
import { SearchTab } from "./SearchTab";
import { SettingsTab } from "./SettingsTab";

const router = createBrowserRouter([
  { path: "/login", element: <LoginPage /> },
  {
    path: "/*",
    element: <AuthenticatedContent />,
    children: [
      { path: "home", element: <HomeTab /> },
      { path: "rate", element: <RateTab /> },
      { path: "search", element: <SearchTab /> },
      { path: "settings", element: <SettingsTab /> },
      { path: "*", element: <Navigate to="home" /> },
    ],
  },
]);

export function AppRouter() {
  return <RouterProvider router={router} />;
}
