import { createContext, ReactNode } from "react";

import { User } from "../models";
import { useUserQuery } from "../hooks/useUserQuery";

export const currentUserContext = createContext<User | null>(null);

export interface CurrentUserProviderProps {
  userId: string;
  children: ReactNode;
}

export function CurrentUserProvider({
  userId,
  children,
}: CurrentUserProviderProps) {
  const { data: user, isLoading } = useUserQuery(userId);

  if (isLoading) {
    return null;
  } else if (user) {
    return (
      <currentUserContext.Provider value={user}>
        {children}
      </currentUserContext.Provider>
    );
  } else {
    return <div>Error: unable to find user with ID: {userId}</div>;
  }
}
