import { ChangeEventHandler, useState } from "react";

import styles from "./EditTagControls.module.css";
import { Tag } from "../models";
import { Button } from "./Button";
import { Input } from "./Input";
import { normalizeTagName } from "../services/tags";
import { useUpdateTagMutation } from "../hooks/useUpdateTagMutation";

export interface EditTagControlsProps {
  tag: Tag;
}

export function EditTagControls({ tag }: EditTagControlsProps) {
  const [name, setName] = useState(tag.name);
  const [alternateName, setAlternateName] = useState(tag.alternateName);
  const { mutate: updateTag } = useUpdateTagMutation();
  const isUpdateButtonDisabled =
    tag.name === name && tag.alternateName === alternateName;

  function handleSave() {
    updateTag({
      name: normalizeTagName(name),
      alternateName: alternateName ? normalizeTagName(alternateName) : null,
      id: tag.id,
    });
  }

  const handleAlternateNameChange: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const trimmedValue = event.target.value.trim();
    if (trimmedValue.length > 0) {
      setAlternateName(event.target.value);
    } else {
      setAlternateName(null);
    }
  };

  return (
    <div className={styles.container}>
      <Input
        value={name}
        onChange={(event) => setName(event.target.value)}
        placeholder="Name"
        className={styles.input}
      />
      <Input
        value={alternateName || ""}
        onChange={handleAlternateNameChange}
        placeholder="Alternate name"
        className={styles.input}
      />
      <Button
        className={styles.button}
        onClick={handleSave}
        disabled={isUpdateButtonDisabled}
      >
        Save
      </Button>
    </div>
  );
}
