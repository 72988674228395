import {
  collection,
  CollectionReference,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";

import { User } from "../models";
import { firestore } from "./firebase";

const usersCollection = collection(
  firestore,
  "users"
) as CollectionReference<User>;

export async function getUsers(): Promise<User[]> {
  const querySnapshot = await getDocs(usersCollection);
  return querySnapshot.docs.map((doc) => doc.data());
}

export async function getUserById(id: string): Promise<User | null> {
  const docSnapshot = await getDoc(doc(usersCollection, id));
  if (!docSnapshot.exists()) {
    return null;
  }
  return docSnapshot.data();
}

export async function updateUser(user: User, updates: Partial<User>) {
  await updateDoc(doc(usersCollection, user.id), updates);
}
