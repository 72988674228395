import { QueryClient, QueryClientProvider } from "react-query";

import { AppRouter } from "./AppRouter";
import { AuthProvider } from "./AuthProvider";

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AppRouter />
      </AuthProvider>
    </QueryClientProvider>
  );
}
