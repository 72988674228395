import {
  faHeart,
  faHeartCrack,
  faTrashCan,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Button } from "./Button";
import styles from "./RateControls.module.css";

export interface RateControlsProps {
  onClickLike: () => void;
  onClickDislike: () => void;
  onClickComments: () => void;
  onClickDelete: () => void;
}

export function RateControls({
  onClickLike,
  onClickDislike,
  onClickComments,
  onClickDelete,
}: RateControlsProps) {
  return (
    <div className={styles.rateControls}>
      <Button onClick={onClickDelete}>
        <FontAwesomeIcon icon={faTrashCan} />
      </Button>
      <button
        className={classNames(styles.fab, styles.dislikeFab)}
        onClick={onClickDislike}
      >
        <FontAwesomeIcon icon={faHeartCrack} />
      </button>
      <button
        className={classNames(styles.fab, styles.likeFab)}
        onClick={onClickLike}
      >
        <FontAwesomeIcon className={styles.heart} icon={faHeart} />
      </button>
      <Button onClick={onClickComments}>
        <FontAwesomeIcon icon={faComment} />
      </Button>
    </div>
  );
}
