import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

const showCommentsForPostIdParam = "showCommentsForPostId";
const showNewPostPanelParam = "showNewPostPanel";
const showTagsForPostIdParam = "showTagsForPostId";
const showNotificationsPanelParam = "showNotificationsPanel";
const showTagFeedPanelParam = "showTagFeedPanel";
const showSinglePostIdParam = "showSinglePostId";

export function usePanelNavigation() {
  const [searchParams, setSearchParams] = useSearchParams();
  const showCommentsForPostId = searchParams.get(showCommentsForPostIdParam);
  const showTagsForPostId = searchParams.get(showTagsForPostIdParam);
  const showSinglePostId = searchParams.get(showSinglePostIdParam);

  const isNewPostPanelVisible = searchParams.has(showNewPostPanelParam);
  const isCommentsPanelVisible = !!showCommentsForPostId;
  const isPostTagsPanelVisible = !!showTagsForPostId;
  const isSinglePostPanelVisible = !!showSinglePostId;
  const isTagFeedVisible = searchParams.has(showTagFeedPanelParam);
  const isNotificationsPanelVisible = searchParams.has(
    showNotificationsPanelParam
  );

  const showCommentsPanel = useCallback(
    (postId: string) => {
      searchParams.set(showCommentsForPostIdParam, postId);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const hideCommentsPanel = useCallback(() => {
    searchParams.delete(showCommentsForPostIdParam);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const showNewPostPanel = useCallback(() => {
    searchParams.set(showNewPostPanelParam, "");
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const hideNewPostPanel = useCallback(() => {
    searchParams.delete(showNewPostPanelParam);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const showPostTagsPanel = useCallback(
    (postId: string) => {
      searchParams.set(showTagsForPostIdParam, postId);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const hidePostTagsPanel = useCallback(() => {
    searchParams.delete(showTagsForPostIdParam);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const showSinglePostPanel = useCallback(
    (postId: string) => {
      searchParams.set(showSinglePostIdParam, postId);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const hideSinglePostPanel = useCallback(() => {
    searchParams.delete(showSinglePostIdParam);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const showTagFeedPanel = useCallback(() => {
    searchParams.set(showTagFeedPanelParam, "");
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const hideTagFeedPanel = useCallback(() => {
    searchParams.delete(showTagFeedPanelParam);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const showNotificationsPanel = useCallback(() => {
    searchParams.set(showNotificationsPanelParam, "");
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const hideNotificationsPanel = useCallback(() => {
    searchParams.delete(showNotificationsPanelParam);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  return {
    isNewPostPanelVisible,
    showNewPostPanel,
    hideNewPostPanel,

    isCommentsPanelVisible,
    showCommentsPanel,
    hideCommentsPanel,

    isPostTagsPanelVisible,
    showPostTagsPanel,
    hidePostTagsPanel,

    isSinglePostPanelVisible,
    showSinglePostPanel,
    hideSinglePostPanel,

    isTagFeedVisible,
    showTagFeedPanel,
    hideTagFeedPanel,

    isNotificationsPanelVisible,
    showNotificationsPanel,
    hideNotificationsPanel,

    params: {
      showCommentsForPostId,
      showTagsForPostId,
      showSinglePostId,
    },
  };
}
