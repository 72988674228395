import { RefCallback, useState } from "react";

import styles from "./AbbreviatedComment.module.css";
import { Comment } from "../models";
import { Button } from "./Button";
import { useUserQuery } from "../hooks/useUserQuery";

export interface AbbreviatedCommentProps {
  comment: Comment;
  onClick: () => void;
}

export function AbbreviatedComment({
  comment,
  onClick,
}: AbbreviatedCommentProps) {
  const [shouldClamp, setShouldClamp] = useState(true);
  const [isClamped, setIsClamped] = useState(false);
  const { data: user, isLoading } = useUserQuery(comment.userId);

  const detectClamp: RefCallback<HTMLSpanElement> = (element) => {
    if (element) {
      setIsClamped(element.offsetHeight < element.scrollHeight);
    }
  };

  function internalOnClick() {
    if (isClamped) {
      setShouldClamp(false);
    } else {
      onClick();
    }
  }

  return isLoading || !user ? null : (
    <Button onClick={internalOnClick} className={styles.commentButton}>
      <span className={styles.abbreviatedComment}>
        <span
          ref={detectClamp}
          className={shouldClamp ? styles.clamped : undefined}
        >
          <strong>{user.displayName}</strong> {comment.content}
        </span>
        {isClamped && <span className={styles.more}>more</span>}
      </span>
    </Button>
  );
}
