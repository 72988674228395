import { useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./LoginPage.module.css";
import { Button } from "./Button";
import { Input } from "./Input";
import { SafeAreaFullHeightDiv } from "./SafeAreaFullHeightDiv";
import { useAuth } from "../hooks/useAuth";

export function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (
    event
  ) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await auth.signIn(email, password);
      navigate("/home", { replace: true });
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <SafeAreaFullHeightDiv>
      {isError && (
        <div className={styles.error}>Incorrect email or password</div>
      )}
      <form className={styles.form} onSubmit={handleSubmit}>
        <Input
          type="email"
          aria-label="email"
          placeholder="Email"
          className={styles.input}
          onChange={(event) => setEmail(event.target.value)}
        />
        <Input
          type="password"
          aria-label="password"
          placeholder="Password"
          className={styles.input}
          onChange={(event) => setPassword(event.target.value)}
        />
        <Button type="submit" disabled={isLoading}>
          {isLoading ? "Logging in..." : "Submit"}
        </Button>
      </form>
    </SafeAreaFullHeightDiv>
  );
}
