import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as faHeartOutline } from "@fortawesome/free-regular-svg-icons";
import {
  faHeart,
  faComment,
  faTrashCan,
  faTags,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./FeedControls.module.css";
import { Button } from "./Button";

export interface PostActionBarProps {
  isLiked: boolean;
  onClickDelete: () => void;
  onClickTags: () => void;
  onClickComments: () => void;
  onClickLikeToggle: () => void;
}

export function FeedControls({
  isLiked,
  onClickDelete,
  onClickTags,
  onClickComments,
  onClickLikeToggle,
}: PostActionBarProps) {
  return (
    <div className={styles.feedControls}>
      <Button onClick={onClickDelete}>
        <FontAwesomeIcon icon={faTrashCan} />
      </Button>
      <Button onClick={onClickTags}>
        <FontAwesomeIcon icon={faTags} />
      </Button>
      <Button onClick={onClickComments}>
        <FontAwesomeIcon icon={faComment} />
      </Button>
      <Button onClick={onClickLikeToggle}>
        <FontAwesomeIcon
          icon={isLiked ? faHeart : faHeartOutline}
          className={isLiked ? styles.liked : undefined}
        />
      </Button>
    </div>
  );
}
