import { RefObject, useState } from "react";

import styles from "./HomeTab.module.css";
import { FilterBy, GetPostsOptions, SortBy } from "../services/posts";
import { SortAndFilterControls } from "./SortAndFilterControls";
import { InfinitePostFeed } from "./InfinitePostFeed";
import { useOutletContext } from "react-router-dom";

export function HomeTab() {
  const [filterBy, setFilterBy] = useState(FilterBy.All);
  const [sortBy, setSortBy] = useState(SortBy.Newest);
  const { scrollToTopRef } =
    useOutletContext<{ scrollToTopRef: RefObject<HTMLDivElement> }>();
  const getPostOptions: GetPostsOptions = { sortBy, filterBy, limit: 5 };

  return (
    <div className={styles.container}>
      <SortAndFilterControls
        filterBy={filterBy}
        sortBy={sortBy}
        onChangeFilterBy={setFilterBy}
        onChangeSortBy={setSortBy}
      />
      <div className={styles.content} ref={scrollToTopRef}>
        <InfinitePostFeed getPostsOptions={getPostOptions} />
      </div>
    </div>
  );
}
