import styles from "./CommentNotification.module.css";
import { Comment } from "../models";
import { usePostQuery } from "../hooks/usePostQuery";
import { useUserQuery } from "../hooks/useUserQuery";
import { usePanelNavigation } from "../hooks/usePanelNavigation";
import { useUpdateCommentMutation } from "../hooks/useUpdateCommentMutation";

export interface CommentNotificationProps {
  comment: Comment;
}

export function CommentNotification(props: CommentNotificationProps) {
  const { comment } = props;
  const { data: post, isLoading: isLoadingPost } = usePostQuery(comment.postId);
  const { data: user, isLoading: isLoadingUser } = useUserQuery(comment.userId);
  const { mutate: updateComment } = useUpdateCommentMutation();
  const panelNavigation = usePanelNavigation();

  function handleClick() {
    if (post) {
      updateComment({
        postId: post.id,
        commentId: comment.id,
        updates: { unread: false },
      });
      panelNavigation.showSinglePostPanel(post.id);
    }
  }

  if (isLoadingPost || isLoadingUser) {
    return null;
  }

  return (
    <div className={styles.container} onClick={handleClick}>
      <div className={styles.text}>
        <strong>{user?.displayName}</strong> commented: {comment.content}
      </div>
      <div
        className={styles.thumbnail}
        style={{
          backgroundImage: `url('${post?.images[0].url}')`,
        }}
      ></div>
    </div>
  );
}
