import styles from "./PostImageLoader.module.css";
import { PostImage } from "../models";

export interface PostImageProps {
  postImage: PostImage;
}

export function PostImageLoader({ postImage }: PostImageProps) {
  return (
    <div
      className={styles.imageContainer}
      style={{
        paddingBottom: calculateBottomPadding(postImage),
      }}
    >
      <img src={postImage.url} alt="post content" className={styles.image} />
    </div>
  );
}

function calculateBottomPadding(image: PostImage) {
  return `${(image.height / image.width) * 100}%`;
}
