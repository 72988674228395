import { FirebaseOptions, initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage, getDownloadURL, ref } from "firebase/storage";

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyAlJa_Tjca9eLjO_XjkvRcsZ2-483rLk3c",
  authDomain: "tumblr-app-d6715.firebaseapp.com",
  databaseURL: "https://tumblr-app-d6715.firebaseio.com",
  storageBucket: "tumblr-app-d6715.appspot.com",
  projectId: "tumblr-app-d6715",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);

export function getDownloadUrl(storagePath: string): Promise<string> {
  return getDownloadURL(ref(storage, storagePath));
}
