import { useEffect, useState } from "react";

import styles from "./InfinitePostFeed.module.css";
import { GetPostsOptions, SortBy } from "../services/posts";
import { PostWithFeedControls } from "./PostWithFeedControls";
import { useInfinitePostsQuery } from "../hooks/useInfinitePostsQuery";
import { FeedFooter } from "./FeedFooter";
import { VisibilityElement } from "./VisibilityElement";
import { Post } from "../models";

export interface InfinitePostFeedProps {
  getPostsOptions: GetPostsOptions;
  scrollToPostId?: string;
}

export function InfinitePostFeed({
  getPostsOptions,
  scrollToPostId,
}: InfinitePostFeedProps) {
  const { isLoading, data, hasNextPage, fetchNextPage } =
    useInfinitePostsQuery(getPostsOptions);
  const [scrollElement, setScrollElement] = useState<HTMLDivElement | null>(
    null
  );

  const allPosts = data?.pages.flat() || [];

  // This has some issues. If the whole page of random posts comes back that have
  // already been seen, it will just sit there on the spinner forever. You can
  // scroll up and scroll back down to try and get another page.
  //
  // It's not noticeable unless the total number of posts is low. A trade off
  // between unlikely jank, and not seeing duplicates when you're sorting by
  // random. I'm not sure which is better.
  const posts =
    getPostsOptions.sortBy === SortBy.Random
      ? deduplicateRandomPosts(allPosts)
      : allPosts;

  useEffect(() => {
    scrollElement?.scrollIntoView({ behavior: "smooth" });
  }, [scrollElement]);

  return (
    <>
      <div className={styles.posts}>
        {posts.map((post) => {
          return (
            <div
              ref={post.id === scrollToPostId ? setScrollElement : undefined}
              key={post.id}
            >
              <PostWithFeedControls post={post} showSeparator />
            </div>
          );
        })}
      </div>
      {hasNextPage && <VisibilityElement onVisible={fetchNextPage} />}
      <FeedFooter showSpinner={isLoading || !!hasNextPage} />
    </>
  );
}

function deduplicateRandomPosts(posts: Post[]): Post[] {
  const set = new Set<string>();
  return posts.filter((post) => {
    if (set.has(post.id)) {
      return false;
    }
    set.add(post.id);
    return true;
  });
}
