import {
  collection,
  CollectionReference,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";

import { Tag, User } from "../models";
import { firestore } from "./firebase";
import { getPost, updatePost } from "./posts";

const tagsCollection = collection(
  firestore,
  "tags"
) as CollectionReference<Tag>;

export async function getTags(): Promise<Tag[]> {
  const querySnapshot = await getDocs(tagsCollection);
  return querySnapshot.docs.map((docSnapshot) => docSnapshot.data());
}

export async function createTag(name: string): Promise<Tag> {
  const docRef = doc(tagsCollection);
  const tag: Tag = {
    id: docRef.id,
    name: normalizeTagName(name),
    alternateName: null,
  };
  await setDoc(docRef, tag);
  return tag;
}

export async function updateTag(updates: Tag): Promise<void> {
  await updateDoc(doc(tagsCollection, updates.id), updates);
}

export function normalizeTagName(name: string): string {
  return name.trim().split(/\s+/).join(" ").toLowerCase();
}

export async function tagPost(postId: string, tags: Tag[]): Promise<void> {
  await updatePost(postId, {
    tags: tags.reduce<Record<string, boolean>>((accumulator, tag) => {
      accumulator[tag.id] = true;
      return accumulator;
    }, {}),
    hasTags: tags.length > 0,
  });
}

export async function getPostTags(postId: string): Promise<Tag[]> {
  const fetchedPost = await getPost(postId);
  if (!fetchedPost) {
    return [];
  }
  const tagIds = Object.keys(fetchedPost.tags);
  const promises = tagIds.map((id) => getDoc(doc(tagsCollection, id)));
  const snapshots = await Promise.all(promises);
  return snapshots
    .map((snapshot) => snapshot.data())
    .filter((data): data is Tag => !!data);
}

export function getPreferredTagName(user: User, tag: Tag): string {
  return user.useAlternateTagNames && tag.alternateName
    ? tag.alternateName
    : tag.name;
}
