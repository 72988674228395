import { Dispatch, SetStateAction, useEffect, useState } from "react";

interface VisibilityTrackerResult {
  /** Ref callback, give this to the element to be observed */
  ref: Dispatch<SetStateAction<HTMLElement | null>>;

  /** Whether or not the observed element is currently visible */
  isVisible: boolean;
}

interface VisibilityTrackerOptions {
  /** Optional callback called when the observed element becomes visible */
  onVisible?: () => void;
}

/**
 * Hook used to track the visibility of an element.
 * @example
 * function Example() {
 *   const { ref, isVisible } = useVisibilityTracker({
 *     onVisible: () =>
 *       console.log("This is called whenever the element becomes visible"),
 *   });
 *
 *   return <div ref={ref}>{isVisible && "Element is visible"}</div>;
 * }
 * @param options Optional configuration
 * @param options.onVisible Callback for when the element becomes visible
 * @returns VisibilityTrackerResult
 */
export function useVisibilityTracker({
  onVisible,
}: VisibilityTrackerOptions = {}): VisibilityTrackerResult {
  const [element, setElement] = useState<HTMLElement | null>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const [{ isIntersecting }] = entries;
      if (isIntersecting) {
        setIsVisible(true);
        onVisible?.();
      } else {
        setIsVisible(false);
      }
    });

    if (element) {
      observer.observe(element);
    }

    return () => observer.disconnect();
  }, [element, onVisible]);

  return { ref: setElement, isVisible };
}
