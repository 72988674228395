import {
  query,
  doc,
  collection,
  getDocs,
  orderBy,
  Timestamp,
  CollectionReference,
  writeBatch,
  limit,
  serverTimestamp,
  collectionGroup,
  where,
  UpdateData,
  updateDoc,
} from "firebase/firestore";

import { Comment } from "../models";
import { firestore } from "./firebase";
import { postsCollection } from "./posts";

export function getComments(postId: string): Promise<Comment[]> {
  return getDocs(
    query(getCommentsCollection(postId), orderBy("createdAt", "asc"))
  ).then((snapshots) => snapshots.docs.map((doc) => doc.data()));
}

export async function createComment(
  userId: string,
  postId: string,
  content: string
) {
  const batch = writeBatch(firestore);
  const postRef = doc(postsCollection, postId);
  const commentRef = doc(getCommentsCollection(postId));

  batch.set(commentRef, {
    userId,
    postId,
    content,
    unread: true,
    id: commentRef.id,
    createdAt: serverTimestamp(),
  });
  batch.update(postRef, {
    hasComments: true,
    lastCommentAt: serverTimestamp(),
  });

  await batch.commit();
}

export async function deleteComment(postId: string, commentId: string) {
  const batch = writeBatch(firestore);
  const postRef = doc(postsCollection, postId);
  const commentRef = doc(getCommentsCollection(postId), commentId);

  // Querying for data like this before doing a batched write is probably
  // vulnerable to race conditions, might need to refactor
  const querySnapshot = await getDocs(
    query(getCommentsCollection(postId), orderBy("createdAt", "desc"), limit(2))
  );

  const recentComments = querySnapshot.docs
    .map((docSnapshot) => docSnapshot.data())
    .filter((recentComment) => recentComment.id !== commentId);

  let lastCommentAt: Timestamp | null = null;
  let hasComments = false;
  if (recentComments.length > 0) {
    lastCommentAt = recentComments[0].createdAt;
    hasComments = true;
  }

  batch.delete(commentRef);
  batch.update(postRef, { lastCommentAt, hasComments });
  await batch.commit();
}

export async function getUnreadComments(userId: string) {
  const querySnapshot = await getDocs(
    query(
      collectionGroup(firestore, "comments"),
      where("unread", "==", true),
      where("userId", "!=", userId)
    )
  );
  return querySnapshot.docs.map((docSnapshot) =>
    docSnapshot.data()
  ) as Comment[];
}

export async function updateComment(
  postId: string,
  commentId: string,
  updates: UpdateData<Comment>
) {
  const commentRef = doc(getCommentsCollection(postId), commentId);
  await updateDoc(commentRef, updates);
}

export function getCommentsCollection(postId: string) {
  return collection(
    firestore,
    "posts",
    postId,
    "comments"
  ) as CollectionReference<Comment>;
}
