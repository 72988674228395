import { ReactNode, Children } from "react";

import styles from "./Masonry.module.css";

export interface MasonryProps {
  columns: number;
  children: ReactNode;
}

export function Masonry({ columns, children }: MasonryProps) {
  const columnGroups: ReactNode[][] = [];

  for (let i = 0; i < columns; i++) {
    columnGroups.push([]);
  }

  Children.forEach(children, (child, i) => {
    columnGroups[i % columns].push(child);
  });

  return (
    <div className={styles.container}>
      {columnGroups.map((group, i) => {
        return (
          <div key={i} className={styles.column}>
            {group}
          </div>
        );
      })}
    </div>
  );
}
