import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./TagFeedPanel.module.css";
import { Button } from "./Button";
import { SwipeablePanel } from "./SwipeablePanel";
import { InfinitePostFeed } from "./InfinitePostFeed";
import { GetPostsOptions } from "../services/posts";
import { getPreferredTagName } from "../services/tags";
import { useCurrentUser } from "../hooks/useCurrentUser";

export interface TagFeedPanelProps {
  isVisible: boolean;
  getPostsOptions: GetPostsOptions;
  scrollToPostId?: string;
  onHide: () => void;
}

export function TagFeedPanel(props: TagFeedPanelProps) {
  const { isVisible, getPostsOptions, scrollToPostId, onHide } = props;
  const { tags } = getPostsOptions;
  const user = useCurrentUser();
  const title =
    tags && tags.length > 0
      ? tags.map((tag) => getPreferredTagName(user, tag)).join(", ")
      : "All posts";

  return (
    <SwipeablePanel
      isVisible={isVisible}
      onHide={onHide}
      topLeft={
        <Button onClick={onHide}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </Button>
      }
      topCenter={title}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <InfinitePostFeed
            getPostsOptions={getPostsOptions}
            scrollToPostId={scrollToPostId}
          />
        </div>
      </div>
    </SwipeablePanel>
  );
}
