import { useRef, useState, KeyboardEventHandler } from "react";

import styles from "./TagPicker.module.css";
import { Tag } from "../models";
import { TagBadge } from "./TagBadge";
import { Button } from "./Button";
import { getPreferredTagName, normalizeTagName } from "../services/tags";
import { useTagsQuery } from "../hooks/useTagsQuery";
import { useCreateTagMutation } from "../hooks/useCreateTagMutation";
import { useCurrentUser } from "../hooks/useCurrentUser";

export interface TagPickerProps {
  value: Tag[];
  allowCreation?: boolean;
  onChange: (tags: Tag[]) => void;
}

export function TagPicker({ value, allowCreation, onChange }: TagPickerProps) {
  const [searchValue, setSearchValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const user = useCurrentUser();

  const selectedTagsById = value.reduce((accumulator, tag) => {
    accumulator.set(tag.id, tag);
    return accumulator;
  }, new Map<string, Tag>());

  const { data: allTags = [] } = useTagsQuery();
  const { mutateAsync: createTag, isLoading: isLoadingCreate } =
    useCreateTagMutation();

  const normalizedSearchValue = normalizeTagName(searchValue);

  const filteredTags = allTags
    .filter((tag) => {
      return (
        !selectedTagsById.has(tag.id) &&
        getPreferredTagName(user, tag).includes(normalizedSearchValue)
      );
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  const shouldShowCreateButton =
    allowCreation &&
    (isLoadingCreate ||
      (normalizedSearchValue &&
        allTags.every(
          (tag) => getPreferredTagName(user, tag) !== normalizedSearchValue
        )));

  function selectTag(tag: Tag) {
    const newMap = new Map(selectedTagsById);
    newMap.set(tag.id, tag);
    onChange(Array.from(newMap.values()));
    if (normalizedSearchValue.length > 0) {
      inputRef.current?.focus();
    }
    setSearchValue("");
  }

  function deselectTag(tag: Tag) {
    const newMap = new Map(selectedTagsById);
    newMap.delete(tag.id);
    onChange(Array.from(newMap.values()));
  }

  async function handleCreateTag() {
    if (window.confirm(`Create tag "${normalizedSearchValue}"?`)) {
      const tag = await createTag(normalizedSearchValue);
      selectTag(tag);
      setSearchValue("");
      inputRef.current?.focus();
    }
  }

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (
      event.key === "Enter" &&
      normalizedSearchValue.length > 0 &&
      filteredTags.length > 0
    ) {
      selectTag(filteredTags[0]);
    }

    if (
      event.key === "Backspace" &&
      searchValue.length === 0 &&
      selectedTagsById.size > 0
    ) {
      const lastSelectedTag = Array.from(selectedTagsById.values()).pop();
      if (lastSelectedTag) {
        deselectTag(lastSelectedTag);
      }
    }
  };

  return (
    <>
      <div
        className={styles.tagPicker}
        onClick={() => inputRef.current?.focus()}
      >
        {Array.from(selectedTagsById.values()).map((tag) => (
          <TagBadge
            key={tag.id}
            isSelected
            onClick={(event) => {
              event.stopPropagation();
              deselectTag(tag);
            }}
          >
            {getPreferredTagName(user, tag)}
          </TagBadge>
        ))}
        <input
          ref={inputRef}
          value={searchValue}
          onChange={(event) => setSearchValue(event.target.value)}
          onKeyDown={handleKeyDown}
          className={styles.input}
          placeholder={value.length === 0 ? "Search tags" : undefined}
        />
      </div>
      <div className={styles.footer}>
        <div className={styles.tagList}>
          {filteredTags.map((tag) => (
            <TagBadge
              key={tag.id}
              onClick={(event) => {
                event.stopPropagation();
                selectTag(tag);
              }}
            >
              {getPreferredTagName(user, tag)}
            </TagBadge>
          ))}
        </div>
        {shouldShowCreateButton && (
          <Button
            onClick={handleCreateTag}
            className={styles.createButton}
            disabled={isLoadingCreate}
          >
            {isLoadingCreate
              ? "Creating..."
              : `Create tag "${normalizedSearchValue}"`}
          </Button>
        )}
      </div>
    </>
  );
}
