import { useEffect, useState } from "react";

import styles from "./PostTagsPanel.module.css";
import { usePanelNavigation } from "../hooks/usePanelNavigation";
import { usePostQuery } from "../hooks/usePostQuery";
import { usePostTagsQuery } from "../hooks/usePostTagsQuery";
import { useTagPostMutation } from "../hooks/useTagPostMutation";
import { Button } from "./Button";
import { PostImages } from "./PostImages";
import { Tag } from "../models";
import { SwipeablePanel } from "./SwipeablePanel";
import { TagPicker } from "./TagPicker";

export interface PostTagsPanelProps {
  isVisible: boolean;
  onHide: () => void;
}

export function PostTagsPanel(props: PostTagsPanelProps) {
  const { isVisible, onHide } = props;
  const [newTags, setNewTags] = useState<Tag[]>([]);
  const panelNavigation = usePanelNavigation();
  const postId = panelNavigation.params.showTagsForPostId || "";

  const { data: post } = usePostQuery(postId);
  const { data: postTags } = usePostTagsQuery(postId);
  const { mutateAsync: tagPost, isLoading: isLoadingTagPost } =
    useTagPostMutation();

  useEffect(() => {
    if (postTags) {
      setNewTags(postTags.sort((a, b) => a.name.localeCompare(b.name)));
    }
  }, [postTags]);

  async function handleSave() {
    await tagPost({ postId, tags: newTags });
    onHide();
  }

  return (
    <SwipeablePanel
      isVisible={isVisible}
      onHide={onHide}
      className={styles.container}
      topLeft={
        <Button
          disabled={isLoadingTagPost}
          onClick={onHide}
          className={styles.cancel}
        >
          Cancel
        </Button>
      }
      topCenter="Edit post tags"
      topRight={
        <Button disabled={isLoadingTagPost} onClick={handleSave}>
          {isLoadingTagPost ? "Saving..." : "Save"}
        </Button>
      }
    >
      <div className={styles.contentContainer}>
        <div className={styles.content}>
          {post && <PostImages post={post} />}
        </div>
      </div>
      <div
        className={styles.footer}
        onTouchMove={(event) => event.stopPropagation()}
      >
        <TagPicker value={newTags} onChange={setNewTags} allowCreation />
      </div>
    </SwipeablePanel>
  );
}
