import { useInfiniteQuery } from "react-query";

import { Post } from "../models";
import { getPosts, GetPostsOptions } from "../services/posts";

export function useInfinitePostsQuery(options: GetPostsOptions) {
  function getNextPageOfPosts({ pageParam }: { pageParam?: Post }) {
    return getPosts({ ...options, startAfter: pageParam });
  }

  function getNextPageParam(lastPage: Post[]) {
    return lastPage.length > 0 ? lastPage[lastPage.length - 1] : undefined;
  }

  return useInfiniteQuery(
    ["posts", { ...options, startAfter: options.startAfter?.id }],
    getNextPageOfPosts,
    {
      getNextPageParam,
    }
  );
}
