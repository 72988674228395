import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

import styles from "./SinglePostPanel.module.css";
import { SwipeablePanel } from "./SwipeablePanel";
import { Button } from "./Button";
import { Spinner } from "./Spinner";
import { usePanelNavigation } from "../hooks/usePanelNavigation";
import { usePostQuery } from "../hooks/usePostQuery";
import { PostWithFeedControls } from "./PostWithFeedControls";

export interface SinglePostPanelProps {
  isVisible: boolean;
  onHide: () => void;
}

export function SinglePostPanel(props: SinglePostPanelProps) {
  const { isVisible, onHide } = props;
  const panelNavigation = usePanelNavigation();
  const postId = panelNavigation.params.showSinglePostId || "";
  const { data: post, isLoading, isError } = usePostQuery(postId);

  let content;
  if (isLoading) {
    content = (
      <div className={styles.centered}>
        <Spinner />
      </div>
    );
  } else if (isError || !post) {
    content = <div className={styles.centered}>Failed to load post</div>;
  } else {
    content = (
      <>
        <div className={styles.post}>
          <PostWithFeedControls post={post} />
        </div>
      </>
    );
  }

  return (
    <SwipeablePanel
      className={styles.container}
      isVisible={isVisible}
      onHide={onHide}
      topLeft={
        <Button onClick={onHide}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </Button>
      }
      topCenter="Post"
    >
      {content}
    </SwipeablePanel>
  );
}
