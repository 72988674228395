import classNames from "classnames";
import { faHeart, faHeartBroken } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./HeartAnimation.module.css";

export interface HeartAnimationProps {
  isLiked: boolean;
  onAnimationEnd: () => void;
}

export function HeartAnimation({
  isLiked,
  onAnimationEnd,
}: HeartAnimationProps) {
  return (
    <div className={styles.animationContainer}>
      <div
        className={classNames(
          styles.bounceIn,
          isLiked ? styles.liked : styles.disliked
        )}
        onAnimationEnd={onAnimationEnd}
      >
        <FontAwesomeIcon
          size="5x"
          icon={isLiked ? faHeart : faHeartBroken}
          className={styles.icon}
        />
      </div>
    </div>
  );
}
