import { RefObject, useRef } from "react";

export interface ScrollToTopResult<T extends HTMLElement> {
  ref: RefObject<T>;
  scrollToTop: () => void;
}

export function useScrollToTop<T extends HTMLElement>(): ScrollToTopResult<T> {
  const ref = useRef<T>(null);

  function scrollToTop() {
    if (ref.current) {
      const previousOverflowValue = ref.current.style.overflow;
      ref.current.style.overflow = "hidden";
      ref.current.scrollTo({ left: 0, top: 0, behavior: "smooth" });
      ref.current.style.overflow = previousOverflowValue;
    }
  }

  return { ref, scrollToTop };
}
