import { useMutation, useQueryClient } from "react-query";

import { Tag } from "../models";
import { tagPost } from "../services/tags";
import { postQueryKey } from "./usePostQuery";

export function useTagPostMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ postId, tags }: { postId: string; tags: Tag[] }) => {
      return tagPost(postId, tags);
    },
    {
      onSettled: (data, error, { postId }) => {
        queryClient.invalidateQueries(postQueryKey(postId));
      },
    }
  );
}
