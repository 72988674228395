import { ComponentPropsWithoutRef } from "react";
import classNames from "classnames";

import styles from "./Button.module.css";

export function Button(props: ComponentPropsWithoutRef<"button">) {
  return (
    <button {...props} className={classNames(styles.button, props.className)} />
  );
}
