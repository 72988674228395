import { ComponentPropsWithoutRef } from "react";
import classNames from "classnames";

import styles from "./TopBar.module.css";

export function TopBar(props: ComponentPropsWithoutRef<"div">) {
  return (
    <div {...props} className={classNames(styles.topBar, props.className)} />
  );
}
