import { useMutation, useQueryClient } from "react-query";
import { Timestamp } from "firebase/firestore";

import { Comment } from "../models";
import { createComment } from "../services/comments";
import { commentsQueryKey } from "./useCommentsQuery";

export function useCreateCommentMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      userId,
      postId,
      content,
    }: {
      userId: string;
      postId: string;
      content: string;
    }) => {
      return createComment(userId, postId, content);
    },
    {
      onMutate: ({ userId, postId, content }) => {
        const optimisticComment: Comment = {
          userId,
          postId,
          content,
          unread: true,
          id: String(Math.random()),
          createdAt: Timestamp.now(),
        };
        queryClient.setQueryData<Comment[] | undefined>(
          commentsQueryKey(postId),
          (oldComments) => [...(oldComments || []), optimisticComment]
        );
      },
      onSettled: (data, error, { postId }) => {
        queryClient.invalidateQueries(commentsQueryKey(postId));
      },
    }
  );
}
