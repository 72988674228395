import styles from "./FullComment.module.css";
import { DateLabel } from "./DateLabel";
import { Comment } from "../models";
import { useSwipeable } from "../hooks/useSwipeable";
import { useUserQuery } from "../hooks/useUserQuery";

export interface FullCommentProps {
  comment: Comment;
  onSwipeToDelete?: () => void;
}

export function FullComment({ comment, onSwipeToDelete }: FullCommentProps) {
  const swipeProps = useSwipeable({ onSwipeLeft: onSwipeToDelete });
  const { data: user, isLoading } = useUserQuery(comment.userId);

  return isLoading || !user ? null : (
    <div className={styles.deleteLayer}>
      <div className={styles.swipeLayer} {...swipeProps}>
        <div className={styles.content}>
          <strong>{user.displayName}</strong> {comment.content}
        </div>
        <DateLabel date={comment.createdAt.toDate()} />
      </div>
    </div>
  );
}
