import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { ComponentPropsWithoutRef } from "react";

import styles from "./TabButton.module.css";
import { Button } from "./Button";

export interface TabButtonProps extends ComponentPropsWithoutRef<"button"> {
  isActive: boolean;
  icon: IconProp;
  size?: FontAwesomeIconProps["size"];
}

export function TabButton({ icon, isActive, size, ...props }: TabButtonProps) {
  return (
    <Button {...props}>
      <FontAwesomeIcon
        size={size}
        className={isActive ? styles.active : styles.inactive}
        icon={icon}
      />
    </Button>
  );
}
