import styles from "./PostImages.module.css";
import { Post } from "../models";
import { PostImageLoader } from "./PostImageLoader";

export interface PostImagesProps {
  post: Post;
}

export function PostImages({ post }: PostImagesProps) {
  return (
    <div className={styles.imagesContainer}>
      {post.images.map((image) => {
        return <PostImageLoader key={image.url} postImage={image} />;
      })}
    </div>
  );
}
