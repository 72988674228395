import { ComponentPropsWithoutRef } from "react";
import classNames from "classnames";

import styles from "./BottomBar.module.css";

export function BottomBar(props: ComponentPropsWithoutRef<"div">) {
  return (
    <div {...props} className={classNames(styles.bottomBar, props.className)} />
  );
}
