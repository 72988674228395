import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faMountain,
  faHeart,
  faGear,
  faSearch,
  faPlus,
  faEllipsis,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./AuthenticatedContent.module.css";
import { useScrollToTop } from "../hooks/useScrollToTop";
import { Button } from "./Button";
import { TopBar } from "./TopBar";
import { BottomBar } from "./BottomBar";
import { SafeAreaFullHeightDiv } from "./SafeAreaFullHeightDiv";
import { NewPostPanel } from "./NewPostPanel";
import { TabButton } from "./TabButton";
import { RequireAuth } from "./RequireAuth";
import { useAuth } from "../hooks/useAuth";
import { usePanelNavigation } from "../hooks/usePanelNavigation";
import { CommentsPanel } from "./CommentsPanel";
import { PostTagsPanel } from "./PostTagsPanel";
import { Menu } from "./Menu";
import { NotificationsPanel } from "./NotificationsPanel";
import { NotificationsButton } from "./NotificationsButton";
import { SinglePostPanel } from "./SinglePostPanel";

export function AuthenticatedContent() {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { ref: scrollToTopRef, scrollToTop } = useScrollToTop<HTMLDivElement>();
  const panelNavigation = usePanelNavigation();

  return (
    <RequireAuth>
      <SafeAreaFullHeightDiv className={styles.container}>
        <TopBar className={styles.topNav}>
          <div className={styles.topNavLeft}>
            <Button onClick={scrollToTop}>
              <FontAwesomeIcon
                icon={faMountain}
                className={styles.topBarLogo}
              />
            </Button>
            <span>CyansPeak</span>
          </div>
          <div className={styles.topNavRight}>
            <NotificationsButton
              onClick={panelNavigation.showNotificationsPanel}
            />
            <Menu position="right">
              <Menu.Button>
                <FontAwesomeIcon icon={faEllipsis} />
              </Menu.Button>
              <Menu.Dropdown>
                <Menu.Item onClick={() => navigate("/settings")}>
                  Settings
                </Menu.Item>
                <Menu.Item onClick={auth.signOut}>Sign out</Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </div>
        </TopBar>
        <div className={styles.content}>
          <Outlet context={{ scrollToTopRef }} />
        </div>
        <BottomBar className={styles.tabs}>
          <TabButton
            icon={faHome}
            isActive={location.pathname === "/home"}
            onClick={() => navigate("/home")}
          />
          <TabButton
            icon={faSearch}
            isActive={location.pathname === "/search"}
            onClick={() => navigate("/search")}
          />
          <TabButton
            size="lg"
            icon={faPlus}
            isActive={false}
            onClick={panelNavigation.showNewPostPanel}
          />
          <TabButton
            icon={faHeart}
            isActive={location.pathname === "/rate"}
            onClick={() => navigate("/rate")}
          />
          <TabButton
            icon={faGear}
            isActive={location.pathname === "/settings"}
            onClick={() => navigate("/settings")}
          />
        </BottomBar>
      </SafeAreaFullHeightDiv>
      <NewPostPanel
        isVisible={panelNavigation.isNewPostPanelVisible}
        onHide={panelNavigation.hideNewPostPanel}
      />
      <CommentsPanel
        isVisible={panelNavigation.isCommentsPanelVisible}
        onHide={panelNavigation.hideCommentsPanel}
      />
      <PostTagsPanel
        isVisible={panelNavigation.isPostTagsPanelVisible}
        onHide={panelNavigation.hidePostTagsPanel}
      />
      <NotificationsPanel
        isVisible={panelNavigation.isNotificationsPanelVisible}
        onHide={panelNavigation.hideNotificationsPanel}
      />
      <SinglePostPanel
        isVisible={panelNavigation.isSinglePostPanelVisible}
        onHide={panelNavigation.hideSinglePostPanel}
      />
    </RequireAuth>
  );
}
