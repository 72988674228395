import { useState } from "react";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./CommentsPanel.module.css";
import { Comment } from "../models";
import { Button } from "./Button";
import { FullComment } from "./FullComment";
import { Input } from "./Input";
import { Spinner } from "./Spinner";
import { TopBar } from "./TopBar";
import { useCommentsQuery } from "../hooks/useCommentsQuery";
import { useCreateCommentMutation } from "../hooks/useCreateCommentMutation";
import { useDeleteCommentMutation } from "../hooks/useDeleteCommentMutation";
import { useCurrentUser } from "../hooks/useCurrentUser";
import { SwipeablePanel } from "./SwipeablePanel";
import { usePanelNavigation } from "../hooks/usePanelNavigation";

export interface CommentsPanelProps {
  isVisible: boolean;
  onHide: () => void;
}

export function CommentsPanel(props: CommentsPanelProps) {
  const { isVisible, onHide } = props;
  const user = useCurrentUser();

  const [commentInputValue, setCommentInputValue] = useState<string>("");
  const commentInputHasContent = commentInputValue.trim().length > 0;

  const panelNavigation = usePanelNavigation();
  const postId = panelNavigation.params.showCommentsForPostId || "";

  const { mutate: deleteComment } = useDeleteCommentMutation();
  const { mutate: createComment } = useCreateCommentMutation();
  const { data: comments, isLoading, isError } = useCommentsQuery(postId);

  function handleCreateComment() {
    if (commentInputHasContent) {
      createComment({ postId, userId: user.id, content: commentInputValue });
      setCommentInputValue("");
    }
  }

  function createOnSwipeToDeleteHandler(comment: Comment) {
    if (comment.userId !== user.id) {
      return undefined;
    }
    return () => {
      if (window.confirm("Are you sure you want to delete this comment?")) {
        deleteComment({ postId, commentId: comment.id });
      }
    };
  }

  let content;
  if (isLoading) {
    content = (
      <div className={styles.centered}>
        <Spinner />
      </div>
    );
  } else if (isError || !comments) {
    content = <div className={styles.centered}>Failed to load comments</div>;
  } else if (comments.length === 0) {
    content = <div className={styles.centered}>No comments yet 😿</div>;
  } else {
    content = (
      <div className={styles.comments}>
        {comments.map((comment) => (
          <FullComment
            key={comment.id}
            comment={comment}
            onSwipeToDelete={createOnSwipeToDeleteHandler(comment)}
          />
        ))}
      </div>
    );
  }

  return (
    <SwipeablePanel
      className={styles.commentsContainer}
      isVisible={isVisible}
      onHide={onHide}
      topLeft={
        <Button onClick={onHide}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </Button>
      }
      topCenter="Comments"
    >
      <TopBar>
        <Input
          placeholder="New comment"
          value={commentInputValue}
          onChange={(event) => setCommentInputValue(event.target.value)}
        />
        <Button
          disabled={!commentInputHasContent}
          onClick={handleCreateComment}
        >
          Send
        </Button>
      </TopBar>
      {content}
    </SwipeablePanel>
  );
}
