import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

import { CurrentUserProvider } from "./CurrentUserProvider";
import { useAuth } from "../hooks/useAuth";

export interface RequireAuthProps {
  children?: ReactNode;
}

export function RequireAuth({ children }: RequireAuthProps) {
  const auth = useAuth();

  if (!auth.isInitialized) {
    return null;
  } else if (!auth.userId) {
    return <Navigate to="/login" replace />;
  } else {
    return (
      <CurrentUserProvider userId={auth.userId}>{children}</CurrentUserProvider>
    );
  }
}
