import classNames from "classnames";
import { ComponentPropsWithoutRef, ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

import styles from "./TagBadge.module.css";
import { Button } from "./Button";

export interface TagBadgeProps extends ComponentPropsWithoutRef<"button"> {
  children: ReactNode;
  isSelected?: boolean;
}

export function TagBadge({ children, isSelected, ...props }: TagBadgeProps) {
  return (
    <Button
      {...props}
      className={classNames(styles.tag, isSelected && styles.active)}
    >
      <span className={styles.text}>{children}</span>
      {isSelected && <FontAwesomeIcon icon={faXmark} />}
    </Button>
  );
}
