import { useMutation, useQueryClient } from "react-query";

import { deletePost } from "../services/posts";
import { randomUnratedPostQueryKey } from "./useRandomUnratedPostQuery";

export function useDeletePostMutation() {
  const queryClient = useQueryClient();

  return useMutation(deletePost, {
    onSuccess: () => {
      queryClient.invalidateQueries(randomUnratedPostQueryKey);
    },
  });
}
