import { useMutation, useQueryClient } from "react-query";

import { Post, User } from "../models";
import { ratePost } from "../services/posts";
import { randomUnratedPostQueryKey } from "./useRandomUnratedPostQuery";

export function useRatePostMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ user, post, isLiked }: { user: User; post: Post; isLiked: boolean }) => {
      return ratePost(user, post, isLiked);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(randomUnratedPostQueryKey);
      },
    }
  );
}
