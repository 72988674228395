import { useQuery } from "react-query";
import { getPostTags } from "../services/tags";

export function postTagsQueryKey(postId: string) {
  return ["posts", postId, "tags"];
}

export function usePostTagsQuery(postId: string) {
  return useQuery(postTagsQueryKey(postId), () => getPostTags(postId), {
    enabled: !!postId,
  });
}
