import { useState } from "react";

import styles from "./RateTab.module.css";
import { useCurrentUser } from "../hooks/useCurrentUser";
import { PostImages } from "./PostImages";
import { RateControls } from "./RateControls";
import { HeartAnimation } from "./HeartAnimation";
import { useRandomUnratedPostQuery } from "../hooks/useRandomUnratedPostQuery";
import { useSwipeable } from "../hooks/useSwipeable";
import { useDeletePostMutation } from "../hooks/useDeletePostMutation";
import { useRatePostMutation } from "../hooks/useRatePostMutation";
import { usePanelNavigation } from "../hooks/usePanelNavigation";

export function RateTab() {
  const [isHeartAnimationVisible, setIsHeartAnimationVisible] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const user = useCurrentUser();
  const panelNavigation = usePanelNavigation();

  const swipeProps = useSwipeable({
    onSwipeLeft: handleClickDislike,
    onSwipeRight: handleClickLike,
  });

  const { mutate: ratePost, isLoading: isLoadingRatePost } =
    useRatePostMutation();

  const { mutate: deletePost, isLoading: isLoadingDeletePost } =
    useDeletePostMutation();

  const {
    data: post,
    isError,
    isFetching: isFetchingPost,
  } = useRandomUnratedPostQuery(user);

  const isLoading = isFetchingPost || isLoadingDeletePost || isLoadingRatePost;

  function handleClickLike() {
    handleRatePost(true);
  }

  function handleClickDislike() {
    handleRatePost(false);
  }

  function handleRatePost(isLiked: boolean) {
    if (post) {
      setIsLiked(isLiked);
      setIsHeartAnimationVisible(true);
      ratePost({ user, post, isLiked });
    }
  }

  function handleClickDelete() {
    if (post && window.confirm("Are you sure you want to delete this post?")) {
      deletePost(post);
    }
  }

  let content;

  if (isLoading) {
    content = null;
  } else if (isError) {
    content = "Error retrieving post";
  } else if (!post) {
    content = "You've rated everything 🙀";
  } else {
    content = (
      <>
        <div {...swipeProps}>
          <PostImages post={post} />
        </div>
      </>
    );
  }

  return (
    <div className={styles.ratePostsContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.content}>{content}</div>
        {isHeartAnimationVisible && (
          <HeartAnimation
            isLiked={isLiked}
            onAnimationEnd={() => setIsHeartAnimationVisible(false)}
          />
        )}
      </div>
      {post && (
        <RateControls
          onClickLike={handleClickLike}
          onClickDislike={handleClickDislike}
          onClickComments={() => panelNavigation.showCommentsPanel(post.id)}
          onClickDelete={handleClickDelete}
        />
      )}
    </div>
  );
}
