import { ComponentPropsWithoutRef } from "react";
import classNames from "classnames";

import styles from "./Input.module.css";

export function Input(props: ComponentPropsWithoutRef<"input">) {
  return (
    <input {...props} className={classNames(styles.input, props.className)} />
  );
}
