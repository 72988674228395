import { useMutation, useQueryClient } from "react-query";

import { createPost } from "../services/posts";

export function useCreatePostMutation() {
  const queryClient = useQueryClient();

  return useMutation(createPost, {
    onSuccess: () => {
      queryClient.resetQueries("posts");
    },
  });
}
