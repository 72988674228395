import styles from "./DateLabel.module.css";

export interface DateLabelProps {
  date: Date;
}

export function DateLabel({ date }: DateLabelProps) {
  return (
    <div className={styles.dateLabel}>
      {date.toLocaleDateString(undefined, { dateStyle: "long" })}
    </div>
  );
}
