import { useQuery } from "react-query";

import { getComments } from "../services/comments";

export function commentsQueryKey(postId: string) {
  return ["posts", postId, "comments"];
}

export function useCommentsQuery(postId: string) {
  return useQuery(commentsQueryKey(postId), () => getComments(postId), {
    enabled: !!postId,
  });
}
