import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

import styles from "./NotificationsPanel.module.css";
import { SwipeablePanel } from "./SwipeablePanel";
import { Button } from "./Button";
import { useUnreadCommentsQuery } from "../hooks/useUnreadCommentsQuery";
import { useCurrentUser } from "../hooks/useCurrentUser";
import { Spinner } from "./Spinner";
import { CommentNotification } from "./CommentNotification";

export interface NotificationsPanelProps {
  isVisible: boolean;
  onHide: () => void;
}

export function NotificationsPanel(props: NotificationsPanelProps) {
  const { isVisible, onHide } = props;
  const user = useCurrentUser();
  const {
    data: unreadComments,
    isLoading,
    isError,
  } = useUnreadCommentsQuery(user.id);

  let content;

  if (isLoading) {
    content = (
      <div className={styles.centered}>
        <Spinner />
      </div>
    );
  } else if (isError || !unreadComments) {
    content = (
      <div className={styles.centered}>Failed to load notifications</div>
    );
  } else if (unreadComments.length === 0) {
    content = <div className={styles.centered}>All caught up ✅</div>;
  } else {
    content = (
      <div className={styles.notifications}>
        {unreadComments.map((comment) => {
          return <CommentNotification key={comment.id} comment={comment} />;
        })}
      </div>
    );
  }

  return (
    <SwipeablePanel
      className={styles.container}
      isVisible={isVisible}
      onHide={onHide}
      topLeft={
        <Button onClick={onHide}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </Button>
      }
      topCenter="Notifications"
    >
      {content}
    </SwipeablePanel>
  );
}
