import styles from "./FeedFooter.module.css";
import { Spinner } from "./Spinner";

export interface FeedFooterProps {
  showSpinner: boolean;
}

export function FeedFooter({ showSpinner }: FeedFooterProps) {
  return (
    <div className={styles.container}>
      {showSpinner ? <Spinner /> : "You've reached the end 😿"}
    </div>
  );
}
